.blog-content-container {
  font-size: 1.125rem;
  line-height: 1.5 !important;
  font-weight: 300;
  overflow-wrap: anywhere;

  h3 {
    font-size: 1.5rem !important;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 40px;
  }

  h1,
  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  p,
  ol,
  li {
    margin-top: 20px;
    line-height: 30px;
  }

  strong {
    font-weight: 500;
  }

  a {
    color: #3e64ff !important;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }

  blockquote {
    border-left: 6px solid #3974f6;
    margin-left: 0px;
    padding-left: 20px;
    font-weight: 400;
    margin-top: 35px;
    margin-bottom: 35px;
    line-height: 30px;
  }

  figure {
    margin-top: 20px;
  }

  ol {
    padding-inline-start: 40px;
  }

  li {
    list-style: auto;
  }

  iframe {
    margin-top: 20px;
    max-width: 100%;
    height: 200px;
  }

  @media (min-width: 640px) {
    iframe {
      height: 400px;
    }
  }

  @media (min-width: 768px) {
    iframe {
      margin-top: 20px;
      height: 400px;
    }
  }
}
