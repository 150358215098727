.paragraph {
  display: flex;
  font-size: 48px;
  line-height: 1.25;
  max-width: 872px;
  flex-wrap: wrap;
  white-space: pre-wrap;
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 8px;

  .word {
    position: relative;

    .shadow {
      position: absolute;
      opacity: 20%;
    }
  }
}

@media (max-width: 1024px) {
  .paragraph {
    font-size: 30px;
  }
}
